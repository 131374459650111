import React, {useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import {Nav, NavBarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, Navbtn, NavbtnLink} from './NavBarElements';

function Navbar({toggle}) {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true);
        } else{
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
     <>
     <Nav scrollNav={scrollNav} >
         <NavBarContainer>
             <NavLogo to="/" onClick={toggleHome}>
             NLD Search
             </NavLogo>
             <MobileIcon onClick={toggle}>
                 <FaBars/>
             </MobileIcon>
             <NavMenu>
                 <NavItem>
                     <NavLinks 
                     to="about"
                     smooth={true} 
                     duration={500} 
                     spy={true} 
                     exact='true'
                     offset={-80}
                     >About
                     </NavLinks>
                 </NavItem>
                 <NavItem>
                 <NavLinks
                 to="team"
                 smooth={true} 
                 duration={500} 
                 spy={true} 
                 exact='true'
                 offset={-80}
                 >Our Team</NavLinks>
                 </NavItem>
                 <NavItem>
                     <NavLinks to="services"
                     smooth={true} 
                     duration={500} 
                     spy={true} 
                     exact='true'
                     offset={-80}>Services</NavLinks>
                 </NavItem>
                 <NavItem>
                     <NavLinks to="values"
                     smooth={true} 
                     duration={500} 
                     spy={true} 
                     exact='true'
                     offset={-80}
                     >Our Values</NavLinks>
                 </NavItem>
             </NavMenu>
             <Navbtn>
                 {/* <NavbtnLink to="/book">Book An Appointment</NavbtnLink> */}
             </Navbtn>
         </NavBarContainer>
     </Nav>
     </>  
    )
}

export default Navbar
