import React from 'react';
import Icon1 from '../../photos/svg-1.svg'
import Icon2 from '../../photos/svg-2.svg'
import Icon3 from '../../photos/svg-3.svg'
import { ServicesContainer, ServicesWrapper,ServicesH1, ServicesCard, ServicesH2, ServicesP, ServicesIcon  } from './ServicesElements'

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon1}/>
                <ServicesH2>Acquiring Top Talent</ServicesH2>
                <ServicesP>Our seasoned recruiters leverage industry insights and a vast network to source exceptional talent, ensuring a perfect fit for your organization's culture and requirements.</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon2}/>
                <ServicesH2>Industry Expertise</ServicesH2>
                <ServicesP>Our healthcare recruiters have deep industry knowledge and stay current with the latest medical trends. This enables us to understand diverse roles and identify candidates with the precise expertise needed</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon3}/>
                <ServicesH2>Specialized IT Networks</ServicesH2>
                <ServicesP>We leverage specialized healthcare networks to access a pool of top talent, including occupational therapists, physiotherapists, and nurses </ServicesP>
            </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
