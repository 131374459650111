export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'NLD Search',
    headline: 'About us',
    description: (
        <>
            Welcome to NLD Search — where talent meets opportunity, and careers are crafted with precision.
            At NLD Search, we believe in the transformative power of matching exceptional talent with the right opportunities. Our mission is to connect top-tier health care professionals with forward-thinking organizations, fostering mutually beneficial partnerships that drive success.
            <br></br><br></br>
            <h3>HIRE THE BEST TALENT</h3>
            <br></br>
            With a passion for excellence and a commitment to integrity, we stand as your trusted partner in healthcare talent acquisition and career advancement. Our team of dedicated experts has a profound understanding of the healthcare industry, allowing us to navigate the complex and evolving landscape of medical recruitment with insight and precision.
   
        </>
    ),
   // buttonLabel: 'Book an appointment',
    imgStart: false,
    img: require('../../photos/svg-1.svg').default,
    alt: 'people',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'team',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WHO WE ARE',
    headline: 'Our Dynamic Team Fueling the Future',
    description: 'At NLD Search, our team is more than just a group of professionals; we are the architects of success stories. Passionate, dedicated, and driven, each member of our team plays a crucial role in connecting exceptional talent with opportunities that shape careers and drive organizational success',
   // buttonLabel: 'Book an appointment',
    imgStart: true,
    img: require('../../photos/svg-2.svg').default,
    alt: 'women',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'values',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'PROMISE & VALUES',
    headline: 'Building Success On Strong Foundations: Unveiling Our Core Values',
    description: (
        <>
        <br></br>
        <h3>Integrity First</h3>
        <br></br>
           We uphold the highest standards of honesty, transparency, and ethical conduct. Integrity is the foundation of trust, and we believe in building enduring relationships with both our clients and candidates
           <br></br><br></br>
           <h3>Client-Centric Approach</h3>
            <br></br>
            Our clients are at the heart of everything we do. We are dedicated to understanding their unique needs, providing personalized solutions, and delivering exceptional value. Your success is our success
        </>
    ),
  //  buttonLabel: 'Book an appointment',
    imgStart: false,
    img: require('../../photos/svg-3.svg').default,
    alt: 'man',
    dark: true,
    primary: true,
    darkText: false
}

