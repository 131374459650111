import React from 'react'

function booking() {
    return (
        <div style={{whiteSpace: "nowrap"}}>
         <h1 style={{textAlign: "center"}} >Booking System goes in here</h1>   
        </div>
    )
}

export default booking
