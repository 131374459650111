import React, {useState} from 'react'
import Video from '../../video/video.mp4'
import {FirstSectionContainer, FirstSectionBg, VideoBg, FirstSectionContent, FirstSectionH1, FirstSectionT, FirstSectionP, FirstSectionBtnWrapper, ArrowForward, ArrowRight} from './FirstSectionElements';
import {Button} from '../ButtonElements';

const FirstSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <FirstSectionContainer>
            <FirstSectionBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
            </FirstSectionBg>
            <FirstSectionContent>
                <FirstSectionH1>NLD Search</FirstSectionH1>
                <FirstSectionT>
                Navigating Leaders in Healthcare Excellence
                </FirstSectionT>
                <FirstSectionP>
                Your Career, Your Future, Our Commitment
                </FirstSectionP>
                <FirstSectionBtnWrapper>
                    <Button to="about" onMouseEnter={onHover} onMouseLeave={onHover}>
                        Read More {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </FirstSectionBtnWrapper>
            </FirstSectionContent>
        </FirstSectionContainer>
    )
}

export default FirstSection
